const LoginIndicator = () => {
  return null;

  // return (
  //   <div className="mt-auto fixed bottom-0 inset-x-0 shadow-upper bg-white py-5 flex justify-center items-center">
  //     <Link href="/auth/login">
  //       <a className="text-3xl font-bold text-dark-340">ورود / عضویت </a>
  //     </Link>
  //   </div>
  // );
};

export default LoginIndicator;
