import { ReactNode, useEffect } from 'react';
import { use100vh } from 'react-div-100vh';

import Icon from '../common/Icon';
import ClientOnlyPortal from '../util/ClientOnlyPortal';

export interface IModalProps {
  children: ReactNode;
  toggle(visible: boolean): void;
  visible?: boolean;
  closable?: boolean;
  closeButton?: boolean;
  tapClose?: boolean;
  title?: ReactNode;
  width?: string;
  animation?: 'fade' | 'slide' | 'none';
}

const Modal = ({
  children,
  closable = true,
  closeButton = true,
  tapClose = true,
  title = '',
  toggle,
  width = '400px',
  animation = 'none',
}: IModalProps) => {
  const height = use100vh();

  useEffect(() => {
    const wrapper = document.querySelector('.site-wrap');

    document.body.classList.add('overflow-hidden');

    if (wrapper) {
      wrapper.classList.add('filter');
      wrapper.classList.add('blur');
    }

    return () => {
      if (!document.querySelectorAll('.modal-wrap').length) {
        document.body.classList.remove('overflow-hidden');

        if (wrapper) {
          wrapper.classList.remove('filter');
          wrapper.classList.remove('blur');
        }
      }
    };
  }, []);

  return (
    <ClientOnlyPortal selector="body">
      <div className="modal-wrap inset-x-0 fixed top-0" style={{ height: height || '100vh' }}>
        <div onClick={tapClose ? () => toggle(false) : undefined} className="absolute inset-0 bg-black bg-opacity-10" />
        <div className="modal-content-wrap flex items-center justify-center h-full w-full">
          <div
            className={`h-full w-full ${animation == 'slide' && 'animated faster slideInUp'}  ${
              animation == 'fade' && 'animated fadeIn'
            }`}
          >
            <div
              style={{ width }}
              className={`modal-content flex flex-col overflow-auto left-1/2 transform-gpu -translate-x-1/2 absolute inset-y-0 sm:top-10 sm:bottom-10 bg-white z-50 max-w-full shadow-md p-5 sm:rounded-lg`}
            >
              <div className="flex items-center mb-5">
                <h3 className="text-base font-bold">{title}</h3>
                {closable && closeButton && (
                  <button
                    onClick={() => toggle(false)}
                    type="button"
                    className="mr-auto relative z-10 w-11 h-11 rounded-full hover:bg-gray-100 text-dark-340"
                  >
                    <Icon name="close" className="w-8 h-8" />
                  </button>
                )}
              </div>

              <div className="modaal-body-wrap flex-1 min-h-0">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </ClientOnlyPortal>
  );
};

export default Modal;
