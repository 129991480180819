import { LatLngLiteral } from 'leaflet';

import fetchAPI, { neshan } from '.';

export const searchCarModel = (q?: string) => fetchAPI.get(`/cardan/v1/car-models/?q=${q}`);

export const addressSearch = (search: string, latlng: LatLngLiteral) => {
  return neshan.get(`/v1/search?term=${search}&lat=${latlng.lat}&lng=${latlng.lng}`);
};

export const getAddressReverse = (latlng: LatLngLiteral) => {
  return neshan.get(`/v2/reverse?lat=${latlng.lat}&lng=${latlng.lng}`);
};

export const sendContactRequest = (data: any, endpoint: string = '') =>
  fetchAPI.post(`/cardan/v1/contact-forms/${endpoint}`, data);
export const sendInspectionRequest = (data: any) => fetchAPI.post('/cardan/v1/car-inspection/', data);
export const sendBuyRequest = (data: any) => fetchAPI.post('/cardan/v1/buy-car/', data);
export const inspectOTPVerify = (data: { phone: string; otp: string }) =>
  fetchAPI.post('/cardan/v1/car-inspection/verify-otp', data);
export const buyCarOTPVerify = (data: { phone: string; otp: string }) =>
  fetchAPI.post('/cardan/v1/buy-car/verify-otp', data);
export const buyCarCreateOrder = (data: { request_type: 'free' | 'special'; budget?: number }) =>
  fetchAPI.post('/cardan/v1/buy-car/create', data);

export const inspectOrderAddons = (data: {
  legalConsultant: boolean;
  plateChange: boolean;
  termsAgreed: boolean;
  orderNote: string;
  contractValue: number | undefined;
}) => fetchAPI.post('/cardan/v1/car-inspection/addons', data);

export const inspectOrderSchedule = (data: { day: string; hour: string }) =>
  fetchAPI.post('/cardan/v1/car-inspection/schedule', data);

export const buyOrderAddons = (data: { termsAgreed: boolean; orderNote: string }) =>
  fetchAPI.post('/cardan/v1/buy-car/addons', data);

export const buyOrderHashVerify = (data: { order: number; hash: string }) =>
  fetchAPI.post('/cardan/v1/buy-car/hash-verify', data);

export const buyCompleteData = (data: { order: number; hash: string; questions: { [key: string]: any } }) =>
  fetchAPI.post('/cardan/v1/buy-car/complete-data', data);

export const applyCouponCode = (coupon_code: string) =>
  fetchAPI.post('/cardan/v1/car-inspection/coupon', { coupon_code });

export const removeCoupons = () => fetchAPI.delete('/cardan/v1/car-inspection/coupon');

export const processInspectionCheckout = (data: { gateway: string }) =>
  fetchAPI.post('/cardan/v1/car-inspection/checkout', data);

export const fetchSEOTags = async (path: string) =>
  await fetchAPI
    .get(`/rankmath/v1/getHead?url=${process.env.NEXT_HOME_URL + path}`)
    .then(({ data }) => data.head)
    .catch((err) => {
      console.info(err);
      throw new Error('Could not fetch page data');
    });

export const trackOrderStatus = (id: string) => fetchAPI.get(`/cardan/v1/track-order/?id=${id}`);
export const fetchUserQuestions = () => fetchAPI.get(`/cardan/v1/user-questions`);
