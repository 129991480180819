import MobileNav from './MobileNav';

const SiteHeader = () => {
  return (
    <>
      <header className="flex relative text-5xl h-20 select-none font-black mb-8">
        <MobileNav />
        <div className="bg-primary flex items-center justify-end pl-2 text-dark-900 w-1/2">کاردان</div>
        <div className="bg-dark-900 flex items-center pr-2 text-white w-1/2">موتورز</div>
      </header>
    </>
  );
};

export default SiteHeader;
