import parse from 'html-react-parser';
import Head from 'next/head';
import { ReactNode } from 'react';
import { use100vh } from 'react-div-100vh';

import LoginIndicator from '../LoginIndicator';
import SiteHeader from '../SiteHeader';

export interface RequestsLayoutProps {
  children: ReactNode;
  seo?: string;
}

const RequestsLayout = ({ children, seo = '' }: RequestsLayoutProps) => {
  const height = use100vh();

  // useEffect(() => {
  //   document.body.classList.add('overflow-hidden');

  //   return () => {
  //     document.body.classList.remove('overflow-hidden');
  //   };
  // }, []);

  return (
    <div className="flex site-wrap transition-all min-h-screen requests-layout flex-col pb-20">
      <Head>{parse(seo)}</Head>

      <SiteHeader />
      {/* <ServicesIndicator /> */}
      <main className="mx-auto request-layout-main">
        {/* <h1 className="font-black text-center tracking-tighter mb-7 text-dark-300 text-3xl">
          پلتفرم آنلاین <span className="text-primary">کارشناسی خودرو</span>
        </h1> */}

        <div className="container">
          <div className="content-area mx-auto max-w-410 pb-4">{children}</div>
        </div>
      </main>
      <LoginIndicator />
    </div>
  );
};

export default RequestsLayout;
