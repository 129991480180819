/* eslint-disable @next/next/no-html-link-for-pages */
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import Icon from '../common/Icon';
import ClientOnlyPortal from '../util/ClientOnlyPortal';

const MobileNav = () => {
  const [shown, setShown] = useState<boolean>(true);
  const router = useRouter();

  useEffect(() => {
    if (shown) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }

    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [shown]);

  useEffect(() => {
    setShown(false);
  }, [router.pathname]);

  return (
    <>
      <button
        onClick={() => setShown(!shown)}
        className="absolute flex items-center justify-center w-9 h-9 top-1/2 transform-gpu -translate-y-1/2 right-4"
      >
        <Icon name="menu" className="w-8 h-8" />
      </button>

      <ClientOnlyPortal selector="body">
        {shown && <div className="fixed inset-0 bg-black bg-opacity-20" onClick={() => setShown(false)} />}
        <div
          className={`offcanvas-menu py-10 px-5 fixed transform-gpu overflow-auto transition-all inset-y-0 right-0 z-50 bg-white shadow-lg max-w-xs w-full ${
            shown ? '' : 'opacity-0 invisible translate-x-full'
          }`}
        >
          <div className="flex items-center relative justify-center">
            <div>
              <h2 className="font-bold text-2xl text-center">
                <span className="text-primary">کاردان</span> موتورز
              </h2>
              <h3 className="text-neutral-500 text-md text-center">کارشناسی . خرید . فروش</h3>
            </div>

            <button
              onClick={() => setShown(false)}
              className="close absolute top-1/2 w-9 h-9 bg-neutral-100 rounded-full -left-1 transform-gpu -translate-y-1/2"
            >
              <Icon name="close" className="w-6 h-6 text-dark-800" />
            </button>
          </div>

          <nav className="mt-5">
            <a
              href="/"
              className="block w-full border-b border-neutral-700 border-opacity-20 py-3 text-xl text-center text-neutral-700"
            >
              صفحه اصلی
            </a>

            <Link href="/track-order/">
              <a className="block w-full border-b border-neutral-700 border-opacity-20 py-3 text-xl text-center text-neutral-700">
                پیگیری سفارش
              </a>
            </Link>
            <Link href="/about/">
              <a className="block w-full border-b border-neutral-700 border-opacity-20 py-3 text-xl text-center text-neutral-700">
                درباره ما
              </a>
            </Link>
            <Link href="/faq/">
              <a className="block w-full border-b border-neutral-700 border-opacity-20 py-3 text-xl text-center text-neutral-700">
                سوالات متداول
              </a>
            </Link>
            <Link href="/contact/">
              <a className="block w-full border-b border-neutral-700 border-opacity-20 py-3 text-xl text-center text-neutral-700">
                تماس با ما
              </a>
            </Link>
          </nav>
        </div>
      </ClientOnlyPortal>
    </>
  );
};

export default MobileNav;
